<template>
  <b-container>
    <b-row>
      <h2>{{ $t("contact_cv") }}</h2>
      <ul>
        <li v-for="cvItem in this.cv" :key="cvItem.path" >
          <a :href="cvItem.path">
          {{ cvItem.label }}
          </a>
        </li>
      </ul>
    </b-row>
  </b-container>

</template>

<script>
export default {
  name: "CV",
  data: function() {
    return {
      cv: [
          {
            path: "/contact/cv/cv_allemand.pdf",
            label: "Deutsch Version"
          },
          {
            path: "/contact/cv/cv_anglais.pdf",
            label: "English version"
          },
          {
            path: "/contact/cv/cv_francais.pdf",
            label: "Version française"
          }
      ]
    };
  }

}
</script>

<style scoped>

</style>