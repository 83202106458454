<template>
  <b-container>
    <div v-if="bibFile">
      <div v-for="entry in bibtexEntries" :key="bibtexEntries.indexOf(entry)">
        <b>
        <span v-if="doesFieldExist(entry, 'author')">
          {{ renderAuthor(entry) }}
        </span>
          <span v-if="doesFieldExist(entry, 'year')">
            {{ renderField(entry, "year") }}.
          </span>
          <span v-if="doesFieldExist(entry, 'url')" style="margin-left: 20px">
            <b-badge :href="renderField(entry, 'url')"
                     style="font-size:10px"
                     variant="dark">
              (view online)
            </b-badge>
          </span>
        </b>
        <br/>
        <span v-if="doesFieldExist(entry, 'title')">
          {{ renderField(entry, "title") }}.
        </span>
        &nbsp;
          <span v-if="doesFieldExist(entry, 'journal')">
            <i>{{ renderField(entry, 'journal') }}</i>
          </span>
          <span v-if="doesFieldExist(entry, 'booktitle')">
            <i>{{ renderField(entry, 'booktitle')}}</i>
          </span>.
          <span v-if="doesFieldExist(entry, 'volume')">
            {{ renderField(entry, 'volume')}},
          </span>
        <span v-if="doesFieldExist(entry, 'pages')">
            p.{{ renderField(entry, 'pages').replace("--", " to ") }}.
        </span>
        <span v-if="doesFieldExist(entry, 'doi')">
            DOI: {{ renderField(entry, 'doi') }}.
        </span>
        <br/><br/>
      </div>
    </div>
  </b-container>
</template>

<script>
import {parseBibFile, normalizeFieldValue} from "bibtex";
import {getBiblio} from "@/api";


export default {
  name: "BibRendering",
  data: function() {
    return {
      bibFile: null,
      bibtexEntries: ["besnier2021named", "johnson-etal-2021-classical", "besnier-2020-history"],

    }
  },
  methods: {
    renderField(entryName, fieldName) {
      return normalizeFieldValue(this.bibFile.getEntry(entryName).getField(fieldName));
    },
    renderAuthor(entryName) {
      return this.bibFile.getEntry(entryName).getField("author").authors$.reduce(
          (accu, value) => accu+
              value.firstNames.reduce((a2, v2) => a2+" "+v2, "")+" "+
              value.vons+" "+
              value.lastNames+value.jrs.join(" ")+", ", "").replace("\\'e", "é");
    },
    doesFieldExist(entryName, fieldName) {
      return typeof this.bibFile.getEntry(entryName).getField(fieldName) !== "undefined";
    },
    getBibtexFile() {
      getBiblio().then(
          (response) => {
            this.bibtexText = response.data.text;
            this.bibFile = parseBibFile(this.bibtexText);
          }
      );
    }

  },
  mounted() {
    this.getBibtexFile()
  },

}
</script>

<style scoped>

</style>