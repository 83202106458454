export default {

    presentation: "Diese Webseite stellt vor, wofür ich mich interessiere und Entwurfe worauf ich gearbeitet habe.",
    voir_plus_sur_descartes: "Sieh mehr von Descartes",
    presentation_quatre_regles_descartes: "Wir alle sollen diese vier Regeln von <strong>Discours de la Méthode</strong> von René Descartes berücksichtigen, damit seine eigene Vernunft zu führen.",
    presentation_morale_provisoire: "Even if the morality, exposed below, was temporary, it can help us every day.",

    titre_accueil: "Hauptseite",
    titre_projets: "Projekts",
    titre_livres: "Bücher",
    titre_contact: "Kontakt",
    titre_langues: "Sprachen",

    page_not_found: "Seite nicht gefunden. Bitte verändern Sie die Sprache dieser Webseite.",

    contact_questions: "Fragen? Vorschläge? Schreib mir!",

    at: "bei",
    point: "punkt",

    contact_github: "Mein Github Konto",

    contact_labo_strasbourg: "Meine Seite in Strassburgs Labor als ich Doktorant war",
    contact_articles_linux: "Artikeln, die ich für Linux Magazin geschrieben habe.",
    contact_article_programmation_dynamique: "Dynamische Programmierung und Sequenze Aufreihen",
    contact_linkedin: "Mein Linkedin Konto",
    contact_linkedin_profil: "Linkedin Profil",
    contact_membre: "Mitglieder zu ",
    contact_societe_archeologique_de_touraine: "Archeologie Gesellschaft von Touraine",
    contact_site_sat: "Ich programmiere eine Webapp, um Bücher von Bibliothek der Archeologische Gesellschaft von Tours besser zu verwalten. Sie ist verfügbar: ",
    contact_accueil_app_web: "Hauptwebseite",
    contact_societe_astronomique_de_touraine: "Astronomie Gesellschaft von Touraine",
    contact_fondateur_skilvit: "Gründer von Skilvit",
    contact_but_skilvit: " Webseite um eine Therapieüberwachung zu verfolgen.",
    contact_skilvit_appli: "Ein Android App auch existiert und ist verfügbar auf ",
    contact_skilvit_appel_aide: "Ich bediene keine Geld für dieses Web und Android Application, so wenn Sie mir helfen wollen, sagen Sie mir!",
    contact_compte_twitter: "Mein Twitter Konto",
    contact_mes_tweets: "Tweets von clemsciences",
    contact_cv: "Curriculum Vitae",

    information: "Informationen",
    livres_auteur: "Autor",
    livres_editeur: "Verlag",
    livres_annee: "Jahre",
    livres_publie_le: "Veröffentlicht ",
    livres_fiche_de_lecture: "Buchbesprechungen",
    livres_chapeau: "Von September 2019 synthetisiere ich Bücher ich lese.",
    livres_presentation_de_la_methode: "Vorstellung der ",
    livres_methode: "Methode",
    livres_date_creation: "Erstellungsdatum",
    livres_attente: "Bald...",

    precedent: "Vörige",
    suivant: "Nächste",
    projets: "Projekte",
    projets_presentation: "Mein Lieblingsthemen",
    opinions: "Politische Meinungen",
}