<template>
  <b-container>
    <h2>{{ page.titre }}</h2>
    <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-show-count="false">Tweet</a>

    <table class="table table-bordered"
    style="padding: 5px; width: 22em; margin: 50px auto;" v-if="page.auteur">
<!--      :fields="fields" :items="items"-->
      <tbody>
      <tr>
          <th colspan="2" style="text-align: center">{{ $t("informations") | capitalize }}</th>
      </tr>
      <tr>
          <td>{{ $t("livres_auteur") | capitalize}}</td>
          <td>{{ page.auteur }}</td>
      </tr>
      <tr>
          <td>{{ $t("livres_editeur") | capitalize }}</td>
          <td>{{ page.editeur }}</td>
      </tr>
      <tr>
          <td>{{ $t("livres_annee") | capitalize }}</td>
          <td>{{ page.annee }}</td>
      </tr>

      </tbody>

    </table>
    <p v-html="page.html"/>
    <p>{{ $t("livres_publie_le") }} - {{ page.date_publication }}</p>
  </b-container>
</template>

<script>
import {getLivre} from "@/api";

export default {
name: "BookReview",
  props: {
    id_: Number,
  },
  data: function () {
  return {
    page: {},
    fields: [],
    items: []
    }
  },
  methods: {
    retrieveBook: function() {
      getLivre(this.$route.params.id, this.$i18n.locale).then(
          (response) => {
            this.page = response.data.livre;
          }
      )
    }
  },
  mounted() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    recaptchaScript.setAttribute('charSet', 'utf-8');
    document.head.appendChild(recaptchaScript);
    this.retrieveBook();
  },
  filters: {
    capitalize: function(value) {
      if(!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase()+value.slice(1);
    }
  }
}
</script>

<style scoped>
/*.table {*/
/*"padding: 5px; width: 22em; margin: 50px auto;"*/
/*}*/

</style>