<template>
  <b-container>
    <h2>{{ $t("titre_projets") }}</h2>
    <b-row>
      <ul>
        <li v-for="projet in projets" :key="projet.path">
          <router-link :to="projet.path">
            {{ projet.titre }}
          </router-link>
          <p>{{ projet.description }}
            <span v-for="category in projet.categories" :key="projet.path+category" class="category">
              {{ category}}
            </span>
            <span class="year">
              {{ projet.annee }}
            </span>
          </p>
        </li>
      </ul>
<!--      <b-list-group>-->
<!--        <b-list-group-item v-for="projet in projets" :key="projet.path">-->
<!--          <b-jumbotron bg-variant="null" text-variant="null" border-variant="null" style="background-color: #8ae0f5; color: #0d47a1">-->
<!--            <template #header style="color: white;">{{ projet.titre }}</template>-->

<!--            <template #lead>{{ projet.description }}-->
<!--            <span v-for="category in projet.categories" :key="projet.path+category" class="category">-->
<!--              {{ category}}-->
<!--            </span>-->
<!--            <span class="year">-->
<!--              {{ projet.annee }}-->
<!--            </span>-->
<!--            </template>-->

<!--            <hr class="my-4">-->
<!--            <router-link :to="projet.path">-->
<!--              <b-button class="item-back">-->
<!--                Allons-y-->
<!--              </b-button>-->
<!--            </router-link>-->
<!--&lt;!&ndash;            <p>&ndash;&gt;-->
<!--&lt;!&ndash;              It uses utility classes for typography and spacing to space content out within the larger&ndash;&gt;-->
<!--&lt;!&ndash;              container.&ndash;&gt;-->
<!--&lt;!&ndash;            </p>&ndash;&gt;-->
<!--          </b-jumbotron>-->

<!--        </b-list-group-item>-->
<!--      </b-list-group>-->
    </b-row>
  </b-container>

</template>

<script>
import {getProjets} from "@/api";

export default {
  name: "Projects",
  data: function () {
    return {
      projets: []
    }
  },
  methods: {
    getProjets() {
      getProjets(this.$i18n.locale).then(
          (response) => {
            this.projets = response.data.projets;
          }
      )
    }
  },
  mounted() {
    this.getProjets();
  }
}
</script>

<style scoped>

item-back {
  background-color: #00acc1;
  color: #00acc1;
}

</style>