<template>
	<b-container>
    <b-row>
      <p>{{ $t("presentation")}}</p>
    </b-row>

    <b-row>
      <b-card>
        <b-card-title>{{ $t("Publications")  }}</b-card-title>
        <b-card-body>
          <BibRendering/>
        </b-card-body>
      </b-card>
    </b-row>

    <b-row class="my-3">
    <b-button v-b-toggle.collapse-discours-de-la-methode >
      {{ $t("voir_plus_sur_descartes") }}
    </b-button>
    <b-collapse id="collapse-discours-de-la-methode">
      <div>
          <p v-html="$t('presentation_quatre_regles_descartes')"></p>
      </div>
      <div class="text-justify">
          <blockquote>
              <p>« Le premier eſtoit de ne receuoir iamais aucune choſe pour vraye, que ie ne la connuſſe euidemment eſtre telle : c’eſt a dire, d’euiter ſoigneuſement la Precipitation, & la Preuention ; & de ne comprendre rien de plus en mes iugemens, que ce qui ſe preſenteroit ſi clairement & ſi diſtindement a mon eſprit, que ie n’euſſe aucune occaſion de le mettre en doute.</p>
              <p>Le ſecond, de diuiſer chaſcune des difficultez que i’examinerois, en autant de parcelles qu’il ſe pourroit, & qu’il ſeroit requis pour les mieux reſoudre.</p>
              <p>Le troiſieſme, de conduire par ordre mes penſées, en commençant par les obiets les plus ſimples & les plus ayſez a connoiſtre, pour monter peu a peu, comme par degrez, iuſques a la connoiſſance des plus compoſez ; et ſuppoſant meſme de l’ordre entre ceux qui ne ſe precedent point naturellement les vns les autres.</p>
              <p>Et le dernier, de faire partout des denombremens ſi entiers, & des reueuës ſi generales, que ie fuſſe aſſuré de ne rien omettre. »</p>
          </blockquote>
      </div>

      <div>
          <p>{{ $t("presentation_morale_provisoire") }}</p>
      </div>

      <div class="text-justify" >
          <blockquote>
              <p>« La premiere eſtoit d’obeir aux lois & aux couſtumes de mon païs, retenant conſtanment la religion en laquelle Dieu m’a fait la grace d’eſtre inſtruit dés mon enfance, & me gouuernant, en toute autre choſe, ſuiuant les opinions les plus moderées, & les plus eſloignées de l’excés, qui fuſſent communement receuës en pratique par les mieux ſenſez de ceux auec leſquels i’aurois a viure. [...]</p>
              <p>Ma ſeconde maxime eſtoit d’eſtre le plus ferme & le plus reſolu en mes actions que ie pourrois, & de ne ſuiure pas moins conſtanment les opinions les plus douteuſes, lorſque ie m’y ſerois vne fois determiné, que ſi elles euſſent eſté tres aſſurées. [...]</p>
              <p>Ma troiſieſme maxime eſtoit de taſcher touſiours plutoſt a me vaincre que la fortune, & a changer mes deſirs que l’ordre du monde ; et generalement, de m’accouſtumer a croire qu’il n’y a rien qui ſoit entierement en noſtre pouuoir, que nos penſées, en ſorte qu’aprés que nous auons fait noſtre mieux, touchant les choſes qui nous ſont exterieures, tout ce qui manque de nous reuſſir eſt, au regard de nous, abſolument impoſſible. [...]</p>
              <p>Enfin, pour concluſion de cete Morale, ie m’auiſay de faire vne reueuë ſur les diuerſes occupations qu’ont les hommes en cete vie, pour taſcher a faire chois de la meilleure ; & ſans que ie vueille rien dire de celles des autres, ie penſay que ie ne pouuois mieux que de continuër en celle la meſme ou ie me trouuois, c’eſt a dire, que d’employer toute ma vie a cultiuer ma raiſon, & m’auancer, autant que ie pourrois, en la connoiſſance de la verité, ſuiuant la Methode que ie m’eſtois preſcrite. I’auois eſprouué de ſi extremes contentemens, depuis que i’auois commencé a me ſeruir de cete Methode, que ie ne croyois pas qu’on en puſt receuoir de plus doux, ny de plus innocens, en cete vie ; et deſcouurant tous les iours par ſon moyen quelques veritez, qui me ſembloient aſſez importantes, & communement ignorées des autres hommes, la ſatisfaction que i’en auois rempliſſoit tellement mon eſprit que tout le reſte ne me touchoit point. »</p>
          </blockquote>
      </div>

      <a href="https://fr.wikisource.org/wiki/Discours_de_la_m%C3%A9thode/%C3%89dition_Adam_et_Tannery"><strong>Discours de la méthode édition</strong> d'Adam et Tannery</a>
    </b-collapse>

    </b-row>
	</b-container>

</template>

<script>
import BibRendering from "@/components/BibRendering";
export default {
  name: "WelcomePage",
  components: {BibRendering},
  data: function() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    onSlideStart() {
        this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style scoped>





</style>