<template>
  <b-container>
    <b-navbar toggleable="md" class="navbar-default fixed-top navbar-dark clementbesnier-nav">
      <b-navbar-brand>
        <h1><b>Clément Besnier</b></h1>
      </b-navbar-brand>
      <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>
      <b-collapse is-nav style="height: 1px;" id="navbarSupportedContent">
            <b-navbar-nav>
                <b-nav-item to="/" class="nav-link space-around titre-nav-item">{{ $t("titre_accueil") }}</b-nav-item>
                <b-nav-item to="/projets" class="nav-link space-around titre-nav-item">{{ $t("titre_projets") }}</b-nav-item>
                <b-nav-item to="/livres" class="nav-link space-around titre-nav-item">{{ $t("titre_livres") }}</b-nav-item>
                <b-nav-item to="/contact" class="nav-link space-around titre-nav-item">{{ $t("titre_contact") }}</b-nav-item>
<!--                <b-nav-item>-->
<!--                    <b-nav-item-dropdown text="Créer" class="titre-nav-item">-->
<!--                      <b-dropdown-item to="/auteur/creer">Créer auteur</b-dropdown-item>-->
<!--                      <b-dropdown-item to="/reference-livre/creer">Créer référence</b-dropdown-item>-->
<!--                      <b-dropdown-item to="/enregistrement/creer">Créer enregistrement</b-dropdown-item>-->
<!--                    </b-nav-item-dropdown>-->
<!--                </b-nav-item>-->
                <b-nav-item class="active">
                  <b-nav-item-dropdown :text="titreLangue" class="titre-nav-item">
                    <b-dropdown-item-button v-for="language in languages" :key="language.value" @click="chosenLanguage=language.value">{{ language.language }}</b-dropdown-item-button>
                  </b-nav-item-dropdown>
                </b-nav-item>
            </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import i18n from "@/i18n";


export default {
name: "NavBar",
  data: function() {
    return {
      chosenLanguage: "fr",
      languages: [
          {id: 0, value: "fr", language: "Français"},
          {id: 1, value: "en", language: "English"},
          {id: 2, value: "de", language: "Deutsch"}
        ]
    }
  },
  methods: {
    chooseLanguage: function(event) {
      console.log(event);
    },
  },
  watch: {
    chosenLanguage: function (newValue) {
      console.log(newValue);
      i18n.locale = newValue;
    }
  },
  computed: {
    titreLangue: function() {
      return this.$t('titre_langues');
    }
  }
}
</script>

<style>

.active {
  color: white;
}


header h1 {
    color: #6cb0f3;
    margin: 0;
    font-weight: normal;
    font-size: 42px;

}

.navbar {
  background-color: #6cb0f3 !important
}

.bg-primary {
  background-color: #6cb0f3 !important
}

.clementbesnier-nav
{
  width: 100%;
  margin: auto auto;
  background-color: #6cb0f3;

}

.nav-link {
  font-size: 1.2em;
}
</style>