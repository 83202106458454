export default {

    presentation: "This website presents the multiple domains I am interested in, the different projects I (have) worked on, my student and professional life, and ideas I am developing.",
    voir_plus_sur_descartes: "See more on Descartes",
    presentation_quatre_regles_descartes: "We all should keep in mind the four rules, written below, from <strong>Discours de la Méthode</strong>(Speech of the method) by René Descartes to lead our life.",
    presentation_morale_provisoire: "Even if the morality, exposed below, was temporary, it can help us every day.",

    titre_accueil: "Main page",
    titre_projets: "Projects",
    titre_livres: "Books",
    titre_contact: "Contact",
    titre_langues: "Languages",

    page_not_found: "Page not found. Please change the language of the website.",

    contact_questions: "Questions? Suggestions? Contact me!",

    at: "at",
    point: "dot",

    contact_github: "My Github account",

    contact_labo_strasbourg: "My page at Strasbourg's lab when I was a PhD student",
    contact_articles_linux: "Articles I wrote in the French Linux Magazine.",
    contact_article_programmation_dynamique: "Dynamic programming and alignment of sequences",
    contact_linkedin: "My Linkedin account",
    contact_linkedin_profil: "My Linkedin profile",
    contact_membre: "Member of",
    contact_societe_archeologique_de_touraine: "Archaeological Society of Touraine",
    contact_site_sat: "I have developped a web app to manage books of \"Archeological Society of Touraine\" library. It is available at ",
    contact_accueil_app_web: "main page of web app",
    contact_societe_astronomique_de_touraine: "Astronomical Society of Touraine",
    contact_fondateur_skilvit: "Founder of Skilvit",
    contact_but_skilvit: " web site to help watch one's therapeutic monitoring.",
    contact_skilvit_appli: "An Android app also exists and is downloadable on ",
    contact_skilvit_appel_aide: "Skilvit is a benevolent project so if you want to join, just say it.",
    contact_compte_twitter: "My Twitter account",
    contact_mes_tweets: "Tweets by clemsciences",
    contact_cv: "Curriculum Vitae",

    information: "information",
    livres_auteur: "author",
    livres_editeur: "editor",
    livres_annee: "year",
    livres_publie_le: "Published ",
    livres_fiche_de_lecture: "Book reviews",
    livres_chapeau: "From September 2019, I will synthesize the books I read with book reviews. I think that mostly languages and linguistics will be tackled. Other themes like philosophy, politics and sciences will sporadically appear.",
    livres_presentation_de_la_methode: "Presentation of the ",
    livres_methode: "method",
    livres_date_creation: "date of creation",
    livres_attente: "Coming soon...",

    precedent: "Previous",
    suivant: "Next",
    projets: "Projects",
    projets_presentation: "The Presented themes represent for me fields I spend a lot of time on.",
    opinions: "a bit argumented political opinions ",
}