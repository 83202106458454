<template>
  <b-container>
    <b-row>
      <b-col cols="8">

      <h2>{{ $t("titre_contact")}}</h2>
        <ul>
        <li><p> {{ $t('contact_questions') }} : clem [ {{ $t('at') }} ] clementbesnier ({{ $t('point') }}) fr</p></li>

        <li><p> <a href="https://github.com/clemsciences">{{ $t('contact_github') }}</a></p></li>

        <li><p><a href="http://icube-miv.unistra.fr/fr/index.php/Cl%C3%A9ment_Besnier">{{ $t('contact_labo_strasbourg') }}</a></p></li>

        <li>
          <p><a href="https://connect.ed-diamond.com/auteur/view/77159-besnier_clement">{{ $t("contact_articles_linux") }}</a></p>
          <ul>
            <li>
            <p><a href="https://connect.ed-diamond.com/GNU-Linux-Magazine/GLMF-212/Programmation-dynamique-et-alignement-de-sequences">{{ $t('contact_article_programmation_dynamique') }}</a></p>
            </li>
        </ul>
        </li>

        <li><p><router-link to="/contact/cv">CV</router-link></p></li>
        <!--<p><a href="https://www.linkedin.com/in/cl%C3%A9ment-besnier-a5b31aa1/">{{ $t('contact_linkedin') }}</a></p>-->
        <!--<p><div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="fr_FR" data-type="horizontal" data-theme="light" data-vanity="clément-besnier-a5b31aa1"><a class="LI-simple-link" href='https://fr.linkedin.com/in/cl%C3%A9ment-besnier-a5b31aa1?trk=profile-badge'>{{ $t"contact_linkedin_profil") }}</a></div><script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script></p>-->

        <li>
            <p>{{ $t('contact_membre') }} <a href="http://www.societearcheotouraine.eu/">{{ $t('contact_societe_archeologique_de_touraine') }}</a>. <a href="http://www.societearcheotouraine.eu/"> <img class="arrondie" src="images/logo-sat-societe-archeologique-touraine-37-bandeaubloc1.jpg" alt="Logo de la SAT" style="height: 100px"></a></p>
            <p>{{ $t('contact_site_sat') }}
                <a href="https://clementbesnier.eu.pythonanywhere.com/biblio/">{{ $t('contact_accueil_app_web') }}</a>.</p>
        </li>


        <li><p>{{ $t('contact_membre') }} <a href="http://www.astrotouraine.fr/"> {{ $t('contact_societe_astronomique_de_touraine') }}</a>. <a href="http://www.astrotouraine.fr/"><img class="arrondie" src="images/SAstronomieT.png" alt="Logo de la SAT" style="height: 100px"></a></p></li>

        <li><p>{{ $t('contact_fondateur_skilvit') }}, <a href="https://www.skilvit.fr"><img style="height: 30px" class="arrondie" src="/images/Skilvit.png" alt="www.skilvit.fr"></a>{{ $t('contact_but_skilvit') }}</p></li>

        <li><p>{{ $t('contact_skilvit_appli') }} <a href='https://play.google.com/store/apps/details?id=skilvitapp.skilvit&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style="height: 45px;" alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/fr_badge_web_generic.png'/></a>.</p></li>

        <li><p>{{ $t('contact_skilvit_appel_aide') }} </p></li>
        </ul>
      </b-col>
      <b-col cols="4">
      <div class="col-xs-6 cold-sm-5 cold-md-4 col-lg-3">
        <div style="padding: 5px">
            <a class="twitter-timeline" data-width="400" data-height="400" data-theme="dark" href="https://twitter.com/clemsciences?ref_src=twsrc%5Etfw">{{ $t("contact_mes_tweets") }}</a>

        </div>
        <!--<div style="padding: 5px">-->
            <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="fr_FR" data-type="vertical" data-theme="dark" data-vanity="clément-besnier-a5b31aa1"><a class="LI-simple-link" href='https://fr.linkedin.com/in/cl%C3%A9ment-besnier-a5b31aa1?trk=profile-badge'>{{ $t("contact_linkedin_profil") }}</a></div>
        <!--</div>-->
      </div>
    </b-col>
    </b-row>

  </b-container>
</template>

<script>
export default {
name: "Contact",
  mounted() {
  let twitterScript = document.createElement('script')
      twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      twitterScript.setAttribute('charSet', 'utf-8')
      document.head.appendChild(twitterScript);
    // let linkedinScript = document.createElement('script')
    //   linkedinScript.setAttribute('src', 'https://platform.linkedin.com/badges/js/profile.js')
    //   linkedinScript.setAttribute('charSet', 'utf-8')
    //   document.head.appendChild(linkedinScript)
  }
}
</script>

<style scoped>

</style>