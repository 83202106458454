
import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from './languages/fr'
import en from './languages/en'
import de from './languages/de'
Vue.use(VueI18n);
const i18n = new VueI18n({
   locale: 'fr',
   messages: {
       en,
       fr,
       de
   }
});

export default i18n;