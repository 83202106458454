<template>
  <b-container>
    <b-row v-if="projet.html">
      <b-col cols="9"><h2>{{ projet.titre }}</h2></b-col>
      <b-col cols="3"><p>{{ projet.categorie }}</p></b-col>
    </b-row>
    <b-row>
      <p>{{ projet.description }}</p>
    </b-row>
    <b-row><p v-html="transformStaticFileURL(projet.html)"></p></b-row>

  </b-container>

</template>

<script>
import {getProjet} from "@/api";

export default {
name: "Project",
  data: function () {
    return {
      projet: {}
    };
  },
  methods: {
    loadProject() {
      let completePath = this.$route.path;
      console.log(completePath);
      getProjet(this.getAbsolutePath(completePath)).then(
          (response) => {
            this.projet = response.data.projet;
            console.log(this.projet);
          }
      )
    },
    getAbsolutePath(pathString) {
      return pathString.substr(9, pathString.length - 9);
    },
    transformStaticFileURL(body) {
      console.log("body");
      console.log(body);
      return body.replace('?/static/?g', process.env.VUE_APP_SITE_API_URL+'$&');
    }
  },
  mounted() {
    this.loadProject();
  }
}
</script>

<style scoped>

</style>