<template>
  <b-container>
    <b-row><p>Une fois chargé, vous pourrez lire le fichier PDF.</p></b-row>
    <b-row><p>En cas de problème, cliquez sur le lien : <a :href="linkToPDF">{{ linkToPDF }}</a></p></b-row>
  </b-container>
</template>

<script>
// import axios from "axios";

export default {
  name: "PDFViewer",
  data: function() {
    return {
      linkToPDF: ""
    }
  },
  mounted() {
    this.linkToPDF = process.env.VUE_APP_SITE_API_URL+this.$route.path;
    window.location = this.linkToPDF;
    // window.open(linkToPDF, '_blank');
    // axios.get(this.$route.path,
    //     {
    //       responseType: 'arraybuffer'
    //     }).then(
    //         (response) => {
    //           let blob = new Blob([response.data], { type: 'application/pdf' });
    //           window.location = window.URL.createObjectURL(blob);
    //
    //           if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //             window.navigator.msSaveOrOpenBlob(blob);
    //             return;
    //           }
    //
    //           // For other browsers:
    //           // Create a link pointing to the ObjectURL containing the blob.
    //           const data = window.URL.createObjectURL(blob);
    //           let link = document.createElement('a');
    //           link.href = data;
    //           link.target = this.$route.path.substr(this.$route.path.lastIndexOf('/')+1);
    //           link.click();
    //           setTimeout(function(){
    //               // For Firefox it is necessary to delay revoking the ObjectURL
    //               window.URL.revokeObjectURL(data);
    //             }, 100);
    //           });
  }
}
</script>

<style scoped>

</style>