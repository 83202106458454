import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from "axios";
import router from './router'



Vue.use(BootstrapVue)

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_SITE_API_URL;

import i18n from './i18n/index'

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
