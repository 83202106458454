<template>
  <b-container>

  </b-container>
</template>

<script>
export default {
name: "Tribune"
}
</script>

<style scoped>

</style>