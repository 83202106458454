<template>
  <b-container>
    <h2>{{ $t("livres_fiche_de_lecture")}} </h2>
    <p>{{ $t("livres_chapeau") }}</p>
    <p>{{ $t("livres_presentation_de_la_methode") }} <router-link to="/livres/methodes">{{ $t("livres_methode") }}</router-link>.</p>

    <ul v-if="livres.length > 0">
      <li v-for="livre in livres" :key="livre.id">
        <router-link :to="computePath(livre.id)">{{ livre.titre }}</router-link> -
        {{ $t("livres_date_creation") }} {{ livre.date_publication }}
      </li>
    </ul>
  </b-container>
</template>

<script>
import {getLivres} from "@/api";

export default {
name: "BookPageMain",
  data: function () {
    return {
      livres: []
    }
  },
  methods: {
    retrieveBooks() {
      getLivres(this.$i18n.locale).then(
          (response) => {
            this.livres = response.data.livres;
          }
      )
    },
    computePath(id) {
      return `/livres/${id}`;
    }
  },
  mounted() {
    this.retrieveBooks();
  }
}
</script>

<style scoped>

</style>