import Vue from 'vue'
import VueRouter from 'vue-router'
import Contact from "@/components/Contact";
import Projects from "@/components/projects/Projects";
import Tribunes from "@/components/tribune/Tribunes";
import BookPageMain from "@/components/books/BookPageMain";
import BookPage from "@/components/books/BookPage";
import Tribune from "@/components/tribune/Tribune";
import Project from "@/components/projects/Project";
import WelcomePage from "@/components/WelcomePage";
import CV from "@/components/contact/CV";
import PDFViewer from "@/components/PDFViewer";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: WelcomePage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/contact/cv',
    name: "CV",
    component: CV
  },
  {
    path: '/projets',
    name: 'Projets',
    component: Projects
  },
  {
    path: '/projets/:id',
    name: 'Projet',
    component: Project
  },
  {
    path: '/tribunes',
    name: 'Tribunes',
    component: Tribunes
  },
  {
    path: '/tribunes/:id',
    name: 'Tribune',
    component: Tribune
  },
  {
    path: '/livres',
    name: 'Livres',
    component: BookPageMain
  },
  {
    path: '/livres/:id',
    name: 'Livre',
    component: BookPage
  },
  {
    path: '/*.pdf',
    name: '',
    component: PDFViewer
  }
  // {
  //   path: '*',
  //   redirect: to => {
  //     return to;
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
