import axios from "axios";

export function getProjets(language) {
    return axios.get(`/projets`, {language})
}

export function getProjet(page, language) {
    return axios.get(`/projets/${page}`, {language})
}

export function getProjetFile(page, filename, language) {
    return axios.get(`/projets/${page}/${filename}`, {language})
}

export function getThemes(language) {
    return axios.get(`/themes`, {language})
}

export function getLivres(language) {
    return axios.get(`/livres`, {language})
}

export function getLivre(livre, language) {
    return axios.get(`/livres/${livre}`, {language})
}

export function getTribunes(language) {
    return axios.get(`/tribunes`, {language})
}

export function getTribune(tribune, language) {
    return axios.get(`/tribunes/${tribune}`, {language})
}

export function getBiblio() {
    return axios.get("/bibtex/");
}
