export default {
    presentation: "Ce site présente les multiples domaines qui m'intéressent, les différents projets sur lesquels j'ai travaillé, mon parcours étudiant et professionnel, ainsi que les idées que je développe.",
    voir_plus_sur_descartes: "Voir plus sur la méthode de Descartes (oui, c'est bon de la relire)",
    presentation_quatre_regles_descartes: "Nous devrions tous garder à l'esprit les 4 règles, écrites ci-dessous, du <strong>Discours de la méthode</strong> de René Descartes pour conduire sa raison.",
    presentation_morale_provisoire: "Bien que la morale, exposée ci-dessous, fût par provision, c'est-à-dire provisoire, elle peut nous accompagner tous les jours.",

    titre_accueil: "Accueil",
    titre_projets: "Projets",
    titre_livres: "Livres",
    titre_contact: "Contact",
    titre_langues: "Langues",

    page_not_found: "Page non trouvée. Changez la langue de ce site.",

    contact_questions: "Des questions ? Des remarques ? Contactez moi !",

    at: "chez",
    point: "point",

    contact_github: "Mon compte Github",

    contact_labo_strasbourg: "Ma page au laboratoire de Strasbourg quand j'étais docotrant",
    contact_articles_linux: "Les articles que j'ai écrits dans Linux Magazine",
    contact_article_programmation_dynamique: "Programmation dynamique et alignement de séquences",
    contact_linkedin: "Mon compte Linkedin",
    contact_linkedin_profil: "Mon profil Linkedin",
    contact_membre: "Membre du conseil d'administration de la",
    contact_societe_archeologique_de_touraine: "société archéologique de Touraine",
    contact_site_sat: "Je développe actuellement une application web pour gérer les livres de la bibliothèque de la Société Archéologique de Touraine. Elle est disponible :",
    contact_accueil_app_web: "accueil de l'application web",
    contact_societe_astronomique_de_touraine: "société astronomique de Touraine",
    contact_fondateur_skilvit: "Fondateur de Skilvit",
    contact_but_skilvit: " site internet pour faire son suivi thérapeutique.",
    contact_skilvit_appli: "Une application Android existe aussi et est téléchargeable sur ",
    contact_skilvit_appel_aide: "Skilvit est un projet bénévole donc si vous souhaitez rejoindre le projet, faites m'en part.",
    contact_compte_twitter: "Mon compte Twitter",
    contact_mes_tweets: "Tweets par clemsciences",
    contact_cv: "Curriculum Vitae",

    informations: "informations",
    livres_auteur: "auteur",
    livres_editeur: "éditeur",
    livres_annee: "année",
    livres_publie_le: "Publié le ",
    livres_fiche_de_lecture: "Fiches de lectures",
    livres_chapeau: "À l'occasion de la rentrée 2019, j'entreprends de synthétiser les livres que je possède en en faisant des fiches de lecture. Je pense que j'aborderai principalement les langues et la linguistique. Des sujets comme la philosophie, la politique ou encore les sciences.",
    livres_presentation_de_la_methode: "Présentation de la ",
    livres_methode: "méthode",
    livres_date_creation: "date de création",
    livres_attente: "Il faut encore attendre un peu...",

    precedent: "Précédent",
    suivant: "Suivant",
    projets: "Projets",
    projets_presentation: "Les thèmes présentés représentent des domaines sur lesquels j'ai beaucoup investi de temps et de passion.",
    opinions: "Opinions politiques un peu argumentées."
}