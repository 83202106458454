<template>
  <div id="app">
    <div id="nav">
      <NavBar/>
    </div>

    <router-view id="inner-body"/>
    <footer>
      2017-2023 - Clément Besnier
    </footer>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "App",
  components: {
    NavBar,

  }
}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

#nav {
  margin-top: 70px;
}

html{
    width:100%;

}

body {
    font-family: 'Verdana', sans-serif;
    /*padding: 5px 2px 2px 5px;*/
    width: 100%;
}
#inner-body {
  padding: 20px 0 5px 5px;
}

a {
    color: #2a99b6;
}

a:hover {
    color: #33bbdf;
}

nav {
  width: 100%;
    margin: auto auto;

}
footer {
    width: 100%;
    margin: auto auto;
    background: #6cb0f3;
    padding: 20px 30px;
}

div.page {
    width: 100%;
    margin: auto auto;
    background: #6cb0f3;
    padding: 20px 30px;
}

/*header nav ul {*/
/*    list-style: none;*/
/*    color: #6cb0f3;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*header nav ul li {*/
/*    display: inline;*/
/*    margin: 0 8px 0 0;*/
/*    padding: 0;*/
/*}*/


table {
    border-collapse: collapse;
}
td, th /* Mettre une bordure sur les td ET les th */ {
    border: 1px solid black;
}

img.resize {
  max-width:75%;
  max-height:75%;
}

p {
    /*font-size: 0.8em;*/
    text-align: justify;
    margin: 10px;
}

h1 {
    /*font-size: 1.3em;*/
    color: antiquewhite;
}

h2 {
    /*font-size: 1.5em;*/
    color: #005db6;
}
/*@font-face {*/
    /*font-family: ;*/
    /*src: url() format('');*/
/*}*/

/*body*/
/*{*/
    /*background-image: url("");*/
    /*background-attachment: fixed;*/
/*}*/

.bg-primary {
    background-color: #6cb0f3 !important;
}


blockquote {
  background: #dbf4f9;
  border-left: 10px solid #1547ff;
}

.arrondie
{
    -moz-border-radius:7px;
    -webkit-border-radius:7px;
    border-radius:7px;
}

.category {
    font-size: 0.895em;
    font-style: italic;
}

.year {
    font-size: 0.895em;
}

/*.btn-primary {*/
/*    background-color: #318dff;*/
/*}*/
</style>
